import React from 'react'
import classNames from 'classnames'
import * as styles from './index.module.less'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'

interface TestimonyCardProps {
  /**图片地址 */
  imgUrl: any
  /**移动端图片地址，如果未传默认使用pc端对应的值 */
  mbImgUrl?: any
  /**图片alt值 */
  imgAlt?: string
  /**描述的标题 */
  title: string
  /**移动端描述的标题，如果未传默认使用pc端对应的值 */
  mbTitle?: string
  /**描述的内容 */
  text: string[] | string
  /**移动端描述的内容，如果未传默认使用pc端对应的值 */
  mbText?: string
  /**署名 */
  signature: string
  /**移动端署名，如果未传默认使用pc端对应的值 */
  mbSignature?: string
  /**署名自定义样式 */
  signatureClass?: any
  /**图片自定义样式 */
  imgClassName?: string
}

const TestimonyCard: React.FC<TestimonyCardProps> = (props) => {
  const {
    imgUrl,
    mbImgUrl,
    imgAlt,
    title,
    mbTitle,
    text,
    mbText,
    signature,
    mbSignature,
    signatureClass,
    imgClassName,
  } = props

  //判断是否是移动端
  const isMb = useIsWindowWidthSmaller()
  //根据是否为移动端过滤数据，在未传入对应数据的时候使用pc端数据
  const data = {
    img: isMb ? mbImgUrl ?? imgUrl : imgUrl,
    alt: imgAlt,
    title: isMb ? mbTitle ?? title : title,
    text: isMb ? mbText ?? text : text,
    signature: isMb ? mbSignature ?? signature : signature,
  }

  return (
    <div className={classNames({ [styles.testimonyCardWrapper]: !isMb, [styles.testimonyCardWrapper_mb]: isMb })}>
      <div className={classNames(styles.imgWrapper, imgClassName)}>
        <img src={data.img?.publicURL ?? data.img} alt={data.alt} />
      </div>
      <div className={styles.contentWrapper}>
        {/* SS-229 SEO 调整为H4 */}
        <h4 className={styles.title}>{data.title}</h4>
        {Array.isArray(data.text) ? (
          data.text.map((t) => (
            <div key={t} className={styles.text}>
              {t}
            </div>
          ))
        ) : (
          <div className={styles.text}>{data.text}</div>
        )}
        <div className={classNames(styles.signature, signatureClass)}>{data.signature}</div>
      </div>
    </div>
  )
}

export default TestimonyCard
