import React, { CSSProperties } from 'react'
import { RedirectTip } from '../MoreResources'
import * as styles from './index.module.less'
import scfxy from './img/scfxy.png'
import scfxyHover from './img/scfxy_hover.png'
import scfxyHoverMb from './img/scfxy_hover_mb.png'
import scyhhx from './img/scyhhx.png'
import scyhhxHover from './img/scyhhx_hover.png'
import scyhhxHoverMb from './img/scyhhx_hover_mb.png'
import scznyy from './img/scznyy.png'
import scznyyHover from './img/scznyy_hover.png'
import scznyyHoverMb from './img/scznyy_hover_mb.png'
import scyxy from './img/scyxy.png'
import scyxyHover from './img/scyxy_hover.png'
import scyxyHoverMb from './img/scyxy_hover_mb.png'
import scggfx from './img/scggfx.png'
import scggfxHover from './img/scggfx_hover.png'
import scggfxHoverMb from './img/scggfx_hover_mb.png'
import scabtest from './img/scabtest.png'
import scabtestHover from './img/scabtest_hover.png'
import scabtestHoverMb from './img/scabtest_hover_mb.png'
import scsjpt from './img/scsjpt.png'
import scsjptHover from './img/scsjptHover.png'
import scsjptHoverMb from './img/scsjptHoverMb.png'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import sclogo from './img/sclogo.png'
import sclogoHover from './img/sclogoHover.png'
import sclogoHoverMb from './img/sclogoHoverMb.png'
import fxyq from './img/fxyq.png'
import fxyqHover from './img/fxyqHover.png'
import fxyqHoverMb from './img/fxyqHoverMb.png'
import szcnyq from './img/szcnyq.png'
import szcnyqHover from './img/szcnyqHover.png'
import szcnyqHoverMb from './img/szcnyqHoverMb.png'
import abcs from './img/abcs.png'
import abcsHover from './img/abcsHover.png'
import abcsHoverMb from './img/abcsHoverMb.png'
import ggzs from './img/ggzs.png'
import ggzsHover from './img/ggzsHover.png'
import ggzsHoverMb from './img/ggzsHoverMb.png'
import classNames from 'classnames'

interface ExtendedCSSProperties extends CSSProperties {
  '--hover-image': string
}

const config = {
  神策分析云: {
    title: '神策分析云',
    desc: '驱动业务的全场景数据分析平台',
    img: scfxy,
    hover: scfxyHover,
    hoverMb: scfxyHoverMb,
    url: 'https://www.sensorsdata.cn/features/analysisCloud.html',
  },
  神策用户画像: {
    title: '神策用户画像',
    desc: '帮助企业构建用户标签及画像体系',
    img: scyhhx,
    hover: scyhhxHover,
    hoverMb: scyhhxHoverMb,
    url: 'https://www.sensorsdata.cn/product/userTag.html',
  },
  神策营销云: {
    title: '神策营销云',
    desc: '打通全域数据、连接全场景的自动化营销平台',
    img: scyxy,
    hover: scyxyHover,
    hoverMb: scyxyHoverMb,
    url: 'https://www.sensorsdata.cn/features/marketingCloud.html',
  },
  神策智能运营: {
    title: '神策智能运营',
    desc: '基于用户行为洞察的一站式运营平台',
    img: scznyy,
    hover: scznyyHover,
    hoverMb: scznyyHoverMb,
    url: 'https://www.sensorsdata.cn/product/focus.html',
  },
  神策广告分析: {
    title: '神策广告分析',
    desc: '全域深链路广告投放分析系统',
    img: scggfx,
    hover: scggfxHover,
    hoverMb: scggfxHoverMb,
    url: 'https://www.sensorsdata.cn/product/ad.html',
  },
  '神策 A/B Testing': {
    title: '神策 A/B Testing',
    mbTitle: '神策 A/B %b%Testing', //在%b%处换行
    titleBreak: true,
    desc: '快测试驱动高增长',
    img: scabtest,
    smallIcon: true,
    hover: scabtestHover,
    hoverMb: scabtestHoverMb,
    url: 'https://www.sensorsdata.cn/features/ABTesting.html',
  },
  神策数界平台: {
    title: '神策数界平台',
    desc: '面向业务的全域数据管理平台，数据可接入、建模和加工',
    img: scsjpt,
    hover: scsjptHover,
    hoverMb: scsjptHoverMb,
    url: 'https://www.sensorsdata.cn/features/bigdata.html',
  },
  '体验产品 Demo': {
    title: '体验产品 Demo',
    desc: '提供多角色、实时、多维度的数据分析，推动科学智能决策',
    img: sclogo,
    hover: sclogoHover,
    hoverMb: sclogoHoverMb,
    url: 'https://www.sensorsdata.cn/demo/demo.html',
  },
  下载干货资料: {
    title: '下载干货资料',
    desc: '产品及行业相关的研究报告和白皮书，助力技能提升',
    img: sclogo,
    hover: sclogoHover,
    hoverMb: sclogoHoverMb,
    url: 'https://www.sensorsdata.cn/school/library',
  },
  分析引擎: {
    title: '分析引擎',
    desc: '取数计算 + 可视化',
    img: fxyq,
    hover: fxyqHover,
    hoverMb: fxyqHoverMb,
    url: 'https://www.sensorsdata.cn/product/analysis.html',
  },
  受众策略引擎: {
    title: '受众策略引擎',
    desc: '圈人，择时 + 可视化',
    img: szcnyq,
    hover: szcnyqHover,
    hoverMb: szcnyqHoverMb,
    url: 'https://www.sensorsdata.cn/product/focus.html',
  },
  'A/B 测试': {
    title: 'A/B 测试',
    desc: '流量分发 + 效果追踪',
    img: abcs,
    hover: abcsHover,
    hoverMb: abcsHoverMb,
    url: 'https://www.sensorsdata.cn/features/ABTesting.html',
  },
  广告助手: {
    title: '广告助手',
    desc: '国内渠道的归因 + 海外渠道的打通',
    img: ggzs,
    hover: ggzsHover,
    hoverMb: ggzsHoverMb,
    url: 'https://www.sensorsdata.cn/product/ad.html',
  },
}

const Item = ({ title, mbTitle, smallIcon, titleBreak, desc, img, hover, hoverMb, isMb, url }) => {
  const style: ExtendedCSSProperties = {
    '--hover-image': `url(${isMb ? hoverMb : hover})`,
  }
  //对于一些过长的标题需要进行换行处理,约定的换行符：%b%
  const titleEl = () => {
    if (mbTitle && titleBreak) {
      const titleArr = mbTitle.split('%b%')

      return (
        <div className={styles.title}>
          {titleArr.map((item) => (
            <div>{item}</div>
          ))}
        </div>
      )
    } else {
      return <div className={styles.title}>{mbTitle ?? title}</div>
    }
  }
  return (
    <div className={styles.productCardWrapper} style={style} onClick={() => window.open(url, '_blank')}>
      <div className={styles.cardheader}>
        <div className={classNames({ [styles.icon]: !smallIcon, [styles.smallIcon]: smallIcon })}>
          <img src={img} alt={title} />
        </div>
        {isMb && titleEl()}
      </div>
      <div className={styles.contetnWrapper}>
        {!isMb && <div className={styles.title}>{title}</div>}
        <div
          className={classNames({
            [styles.desc]: !titleBreak || !isMb,
            [styles.breakDesc]: (isMb && titleBreak) || (!isMb && smallIcon),
          })}
        >
          {desc}
        </div>
      </div>
      <RedirectTip url={url} text="了解更多" />
    </div>
  )
}

const ProductCards = ({ data = ['神策分析云', '神策用户画像', '神策营销云', '神策智能运营'] }) => {
  // 是否移动端
  const isMb = useIsWindowWidthSmaller()
  return (
    <div className={styles.wrapper}>
      {data?.map((title) => (
        <Item {...config?.[title]} isMb={isMb} key={title} />
      ))}
    </div>
  )
}

export default ProductCards
