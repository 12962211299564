import React, { useMemo } from 'react'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import SwiperCom from '../SwiperCom'
import * as styles from './index.module.less'
import classnames from 'classnames'

export const RedirectTip = ({ text, url = '#', redirectTextClass = '', iconClass = '' }) => {
  const onClick = () => {
    window.open(url, '_blank')
  }

  return (
    <div className={styles.redirectTipWrapper} onClick={onClick}>
      <div className={classnames(styles.redirectText, redirectTextClass)}>{text || '免费下载'}</div>
      <div className={classnames(styles.icon, iconClass)}></div>
      {/* <img src={DownloadIcon} alt="" /> */}
    </div>
  )
}

const RightItem = ({ title, tip, url, img, alt, text }) => {
  return (
    <div className={styles.rightItem} onClick={() => window.open(url, '_blank')}>
      <div className={styles.img}>
        <img src={img?.publicURL ?? img} alt={alt || title} />
      </div>
      <div className={styles.content}>
        <div className={styles.contentTitle}>
          <div className={styles.tag}>{tip}</div>
          <div className={styles.text}>{title}</div>
        </div>
        <RedirectTip url={url} text={text} />
      </div>
    </div>
  )
}

const MbItem = ({ title, tip, url, img, alt, img_mb }) => {
  const onClick = () => {
    window.open(url, '_blank')
  }
  return (
    <div className={styles.mbItemWrapper} onClick={onClick}>
      <div className={styles.img}>
        {!!img_mb && <img src={img_mb?.publicURL ?? img_mb} alt={alt || title} />}
        {!img_mb && <img src={img?.publicURL ?? img} alt={alt || title} />}
      </div>
      <div className={styles.contetnWrapper}>
        <div className={styles.tag}>{tip}</div>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  )
}

const MoreResources: React.FC<any> = ({ data = [], mRleftClass }) => {
  const [leftData, ...rightData] = data

  const isMb = useIsWindowWidthSmaller()
  const { img = '', alt = '', tip = '', title = '', url = '', text = '' } = leftData || {}

  const mbNodes = useMemo(() => data.map((dataItem) => <MbItem {...dataItem} />), [data])

  return isMb ? (
    <SwiperCom nodes={mbNodes} className={styles.mbItemBoxShadow} indicatorType="default" />
  ) : (
    <div className={styles.moreResourcesWrapper}>
      <div className={classnames(styles.mRleft, mRleftClass)} onClick={() => window.open(url, '_blank')}>
        <div className={styles.img}>
          <img src={img?.publicURL ?? img} alt={alt || title} />
        </div>
        <div className={styles.content}>
          <div className={styles.tag}>{tip}</div>
          <div className={styles.text}>{title}</div>
          <RedirectTip text={text} url={url}></RedirectTip>
        </div>
      </div>
      <div className={styles.mRright}>
        {rightData.map((data) => (
          <RightItem {...data} key={data?.title} />
        ))}
      </div>
    </div>
  )
}

export default MoreResources
