// extracted by mini-css-extract-plugin
export var active = "index-module--active--jfHb-";
export var autoWAndH = "index-module--autoWAndH--JsF83";
export var buttons = "index-module--buttons--oUAI5";
export var codeDesc = "index-module--codeDesc--IiROW";
export var contentStart = "index-module--contentStart--HYj40";
export var contentWrapper = "index-module--contentWrapper--0wAlC";
export var detailsWrapper = "index-module--detailsWrapper--msH-V";
export var enintroduction1 = "index-module--enintroduction1--UbspR";
export var fixedWandH = "index-module--fixedWandH--jbNkF";
export var icon = "index-module--icon--7HWga";
export var img = "index-module--img--FjLsa";
export var introduction1 = "index-module--introduction1--4gN-O";
export var introductionList = "index-module--introductionList--LfG8+";
export var itemContenWrapper = "index-module--itemContenWrapper--CwkjO";
export var itemContent = "index-module--itemContent--nn0rY";
export var itemContentAlign = "index-module--itemContentAlign--F8Dlw";
export var listItemWrapper = "index-module--listItemWrapper--WhpEU";
export var longTitle = "index-module--longTitle--NMQM5";
export var tab = "index-module--tab--aPijW";
export var tabSwitcher = "index-module--tabSwitcher--IFfG7";
export var tabs = "index-module--tabs--eOMZO";