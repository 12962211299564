// 引入React库
import React from 'react'
// 引入样式文件
import * as styles from './index.module.less'

// 定义Props接口
interface LeftRightLayoutProps {
  leftContent: React.ReactNode // 左侧内容组件
  rightContent: React.ReactNode // 右侧内容组件
  leftWidth?: number // 左侧区域宽度，默认875
  rightWidth?: number // 右侧区域宽度，默认247
  paddingTop?: number // 上部填充，默认18
  paddingBottom?: number // 默认下部填充为100
}

// 定义左右布局组件
const LeftRightLayout: React.FC<LeftRightLayoutProps> = ({
  leftContent,
  rightContent,
  paddingTop = 18, // 默认上部填充为18
  paddingBottom = 100, // 默认下部填充为100
  leftWidth = 875, // 默认左侧宽度为875
  rightWidth = 247, // 默认右侧宽度为247
}) => {
  // 返回组件JSX
  return (
    <div className={styles.contentWrapper} style={{ paddingTop, paddingBottom }}>
      <div className={styles.leftWrapper} style={{ width: leftWidth }}>
        {leftContent} {/* 渲染左侧内容 */}
      </div>
      <div className={styles.rightWrapper} style={{ width: rightWidth }}>
        {rightContent} {/* 渲染右侧内容 */}
      </div>
    </div>
  )
}

// 导出左右布局组件
export default LeftRightLayout
