import React from 'react'
import * as styles from './index.module.less'

interface SubChapterProps {
  /** 标题 */
  title: string
  /** PC间隔（标题和内容） */
  spacing?: number
  /** 移动端间隔 */
  spacingMb?: string | number
  /** 是否移动端 */
  isMb?: boolean
  children?: React.ReactNode
  /** 标题右侧组件 */
  rightNode?: React.ReactNode
}

const SubChapter: React.FC<SubChapterProps> = ({
  title,
  isMb = false,
  spacing = 16,
  spacingMb = '1.6rem',
  children,
  rightNode,
}) => {
  return (
    <div className={styles.subWrapper}>
      <div className={styles.title} style={{ marginBottom: isMb ? spacingMb : spacing }}>
        {title}
        {rightNode}
      </div>
      {children}
    </div>
  )
}

export default SubChapter
