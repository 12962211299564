import React from 'react'
import classnames from 'classnames'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import * as styles from './index.module.less'

interface DownloadCardProps {
  /**标题 */
  title: string
  /**描述 */
  desc: string
  /**跳转或者下载的描述文字，默认值为：免费下载 */
  linkText?: string
  /**链接 */
  link: string
  /**背景图片 */
  bg?: any
  /**移动端背景图片 */
  mb_bg?: any
}

const DownloadCard: React.FC<DownloadCardProps> = (props) => {
  const rightArrowIcon = 'https://ow-file.sensorsdata.cn/www/features/overview/right_arrow.png'
  const { title, desc, linkText = '免费下载', link, bg, mb_bg } = props

  const isMb = useIsWindowWidthSmaller()

  const customStyle: any = {
    '--mb-image': `url(${mb_bg?.publicURL || mb_bg})`,
  }
  return (
    <div className={styles.downloadCardWrapper} style={isMb ? customStyle : undefined}>
      <div className={styles.leftContent}>
        <div className={styles.title}>{title}</div>
        <div className={styles.desc}>{desc}</div>
        <div className={styles.linkText}>
          <a href={link} target="_blank">
            <span>{linkText}</span>
            <img src={rightArrowIcon} />
            <img src={rightArrowIcon} />
          </a>
        </div>
      </div>
      {!isMb && (
        <div className={styles.rightContent}>
          <img src={bg?.publicURL || bg} />
        </div>
      )}
    </div>
  )
}

export default DownloadCard
