// extracted by mini-css-extract-plugin
export var ShopAnimationContainer = "index-module--ShopAnimationContainer--rzrwP";
export var ShopAnimationWrap = "index-module--ShopAnimationWrap--S9zy1";
export var banner = "index-module--banner--qqD2h";
export var banner_bg = "index-module--banner_bg--buXEW";
export var banner_buttons = "index-module--banner_buttons--Kqdfq";
export var banner_container = "index-module--banner_container--3ItvA";
export var banner_content = "index-module--banner_content--wetna";
export var banner_desc = "index-module--banner_desc--qwtWH";
export var banner_en_bg = "index-module--banner_en_bg--UMvtA";
export var banner_intro = "index-module--banner_intro--RNaZk";
export var banner_media = "index-module--banner_media--2k6-s";
export var banner_title = "index-module--banner_title--QUSU8";
export var banner_titleWrapper = "index-module--banner_titleWrapper--njX3L";
export var en = "index-module--en--geC+C";
export var enTitle = "index-module--enTitle--j4yZl";
export var flexCenter = "index-module--flex-center--f0TCu";
export var logo = "index-module--logo--C4IWP";
export var sdButtonGlass = "index-module--sd-button-glass--F5C1B";
export var videoBtnWra = "index-module--videoBtnWra--FbaJl";