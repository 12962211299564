import React from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface VideoBtnProps {
  /**视频时长,直接传入 1:30 这种形式 */
  duration: string
  /**自定义样式类名 */
  classname?: string
  /**视频ref */
  curVideo: any
}

const VideoBtn: React.FC<VideoBtnProps> = (props) => {
  const { duration, classname, curVideo } = props
  return (
    <div className={classnames(styles.videoBtnWrapper, classname)} onClick={() => curVideo.current?.playVideo()}>
      <div className={styles.playBtnWrapper}>
        <div className={styles.playBtn} />
      </div>
      <div className={styles.duration}>{duration}</div>
    </div>
  )
}

export default VideoBtn
