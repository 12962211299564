import React, { useMemo, useState, useRef, useEffect } from 'react'
import classNames from 'classnames'
import * as styles from './index.module.less'
import li1 from './svg/li1.svg'
import { Button } from 'components/index'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import SwiperCom, { SwiperComInstance } from '../SwiperCom'

const LiIconMap = {
  default: li1,
}

interface DataItem {
  /** tab标题 */
  title: string
  /** 短标题 */
  sortTitle: string
  /** 内容 */
  content: {
    /** 左侧图片 */
    img: string
    /** 右侧描述1 */
    introduction1: string
    /** 右侧list描述 */
    introductionList?: {
      content: string
    }[]
    /** 右侧按钮 */
    buttons?: {
      text: string
      btnType: string
      href: string
    }[]
  }
}

interface TabSwitcherProps {
  /**数据 */
  data: DataItem[]
  /**tab标题是否很长在移动端需要滑动 */
  longTitle?: boolean
  /**元素的flex布局 */
  align?: 'center' | 'flex-start' | 'flex-end' | 'space-between'
  /**图片是否固定长宽 */
  fixedWandH?: boolean
  /**移动端是否展示阴影 */
  mbShowShadow?: boolean
  /**图片长宽自适应 */
  autoWAndH?: boolean
  /**自定义样式 */
  itemContenWrapperClass?: any
  /**自定义tab每个子元素的样式 */
  tabItemClass?: any
  /**自定义tab样式 */
  tabsClass?: any
  detailCenter?: boolean
}

const ItemContent = React.memo<any>(
  ({ data, align, fixedWandH, mbShowShadow, isPc, autoWAndH, itemContenWrapperClass, detailCenter }) => {
    let { content, reverse = false, showIcon = true } = data || {}

    const {
      img,
      alt,
      introduction1 = '',
      enintroduction1 = '',
      mbIntroduction1 = '',
      codeDesc = '',
      introductionList = [],
      mbIntroductionList = [],
      buttons = [],
    } = content || {}

    const contentTitle = isPc ? introduction1 : mbIntroduction1?.length > 0 ? mbIntroduction1 : introduction1
    const contentList = isPc ? introductionList : mbIntroductionList?.length > 0 ? mbIntroductionList : introductionList

    return (
      <div
        className={classNames(
          {
            'md:flex md:flex-row-reverse': reverse,
            [styles.itemContenWrapper]: true,
          },
          itemContenWrapperClass,
        )}
        style={isPc ? { justifyContent: align } : undefined}
      >
        <div
          className={classNames({
            [styles.img]: !fixedWandH && !autoWAndH,
            [styles.fixedWandH]: fixedWandH && !autoWAndH,
            [styles.autoWAndH]: autoWAndH,
            'lg:rounded-[4px] lg:overflow-hidden': autoWAndH,
          })}
          style={mbShowShadow || isPc ? undefined : { boxShadow: 'none' }}
        >
          <img src={img?.publicURL ?? img} alt={alt || ''} />
        </div>
        <div className={classNames(styles.detailsWrapper, { [styles.contentStart]: !detailCenter })}>
          <div className="lg:flex lg:items-end">
            {/* SS-229 SEO 调整为H4 */}
            <h4 className={styles.introduction1}>{contentTitle}</h4>
            {enintroduction1 && <div className={styles.enintroduction1}>{enintroduction1}</div>}
          </div>
          {codeDesc && <div className={styles.codeDesc}>{codeDesc}</div>}
          {contentList.length > 0 && (
            <div className={styles.introductionList}>
              {contentList.map((item) => (
                <div className={styles.listItemWrapper} key={item.content}>
                  {showIcon && (
                    <div className={styles.icon}>
                      <img src={LiIconMap['default']} />
                    </div>
                  )}
                  <div className={classNames(styles.itemContent, { [styles.itemContentAlign]: !detailCenter })}>
                    {item.content}
                  </div>
                </div>
              ))}
            </div>
          )}
          {buttons.length > 0 && (
            <div className={styles.buttons}>
              {buttons.map((item) => {
                const { text = '', ...props } = item
                return (
                  <Button {...props} key={text} target="_blank">
                    {text}
                  </Button>
                )
              })}
            </div>
          )}
        </div>
      </div>
    )
  },
)

const TabSwitcher: React.FC<TabSwitcherProps> = ({
  data,
  longTitle = false,
  align = 'center',
  fixedWandH = false,
  mbShowShadow = true,
  autoWAndH = false,
  itemContenWrapperClass,
  tabItemClass,
  detailCenter = true,
  tabsClass,
}) => {
  const [dataSource, setDataSource] = useState(data || [])
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const swiperComRef = useRef<SwiperComInstance | null>(null)
  // 是否移动端
  const isMb = useIsWindowWidthSmaller()

  const [isPc, setIsPc] = useState(isMb)

  useEffect(() => {
    setIsPc(!isMb)
  }, [isMb])

  const tabs = useMemo(() => dataSource.map((data) => (isMb ? data.sortTitle : data.title)), [dataSource, isMb])

  const handleTabClick = (tabIndex) => {
    setActiveTabIndex(tabIndex)
    swiperComRef.current?.slideTo(tabIndex)
  }

  const content = useMemo(() => {
    const activeItem = dataSource[activeTabIndex]
    return activeItem ? (
      <ItemContent
        data={activeItem}
        align={align}
        isPc={isPc}
        fixedWandH={fixedWandH}
        mbShowShadow={mbShowShadow}
        autoWAndH={autoWAndH}
        itemContenWrapperClass={itemContenWrapperClass}
        detailCenter={detailCenter}
      />
    ) : null
  }, [activeTabIndex, dataSource, isPc])

  // 移动端滑动组件
  const nodes = useMemo(() => {
    return dataSource.map((item, index) => (
      <ItemContent
        data={item}
        key={index}
        isPc={isPc}
        mbShowShadow={mbShowShadow}
        itemContenWrapperClass={itemContenWrapperClass}
        detailCenter={detailCenter}
      />
    ))
  }, [dataSource, isPc])

  // 滑动
  const onSlideChange = (index) => {
    setActiveTabIndex(index)
  }

  return (
    <div className={styles.tabSwitcher}>
      <div className={classNames(styles.tabs, tabsClass)}>
        {tabs.map((tabName, index) => (
          // SS-229 变更为H3标签
          <h3
            key={index}
            className={classNames(
              {
                [styles.tab]: !longTitle,
                [styles.active]: activeTabIndex === index,
                'w-[300px]': !longTitle || !isMb,
                [styles.longTitle]: longTitle,
              },
              tabItemClass,
            )}
            onClick={() => handleTabClick(index)}
          >
            {tabName}
          </h3>
        ))}
      </div>
      {isMb ? (
        <SwiperCom
          ref={swiperComRef}
          onSlideChange={onSlideChange}
          className={styles.contentWrapper}
          nodes={nodes}
          // currentIndex={activeTabIndex}
        />
      ) : (
        <div className={styles.contentWrapper}>{content}</div>
      )}
    </div>
  )
}

export default TabSwitcher
