import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
} from 'react'
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperIndicator from '../SwiperIndicator'
import { debounce } from 'lodash'
import 'swiper/swiper-bundle.css'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'

SwiperCore.use([Navigation, Pagination, Autoplay])

interface SwiperComProps {
  onSlideChange?: (swiper: any) => void
  className?: string
  /** 渲染节点 */
  nodes: React.ReactNode[]
  /** 是否轮播 */
  isAutoplay?: boolean
  /** 轮播间隔时间 */
  autoplayDelay?: number
  /** 进度条类别，默认在外部 */
  indicatorType?: 'default' | 'bottom' | 'none'
  /** 移动端导航条和轮播的距离,对应mbGapMap */
  indicatorGapType?: 'default' | 'short'
  /** 是否开启进度条动画 */
  isAnimation?: boolean
  /** swiper类型，default一个视图只展示一个slider，double可以展示两个, 或者double的是设置对应PC，MB展示的宽度用于控制展示 */
  swiperType?: 'default' | 'double' | { type: 'double'; pcWidth?: string; mbWidth?: string }
}

export interface SwiperComInstance {
  slideTo: (index: number, speed?: number, runCallbacks?: boolean) => void
}

const mbGapMap = {
  default: '2.8rem',
  short: '1.8rem',
}

/** 不同type对应的宽度和间隔 */
const indicatorTypeWS = {
  bottom: [53, 20],
}

const SwiperComInner: ForwardRefRenderFunction<any, SwiperComProps> = (
  {
    onSlideChange: onChange,
    className,
    nodes,
    isAutoplay = false,
    autoplayDelay = 6000,
    indicatorType = 'default',
    indicatorGapType = 'default',
    swiperType = 'default',
    isAnimation,
  },
  ref,
) => {
  const [swiperKey, setSwiperKey] = useState(0)
  const [activeIndex, setActiveIndex] = useState(0)
  const swiperRef = useRef<any>(null)

  // 是否移动端
  const isMb = useIsWindowWidthSmaller()

  // 移动端滑动组件
  const contentSlides = useMemo(() => {
    const isObj = typeof swiperType === 'object'
    const isDoubleSlider = swiperType === 'double' || (isObj && swiperType?.type === 'double') || undefined
    const pcWidth = isObj ? swiperType?.pcWidth || '70%' : '70%'
    const mbWidth = isObj ? swiperType?.mbWidth || '26.6rem' : '26.6rem'

    return nodes.map((item, index) => (
      <SwiperSlide key={index} style={isDoubleSlider && (isMb ? { width: mbWidth } : { width: pcWidth })}>
        {item}
      </SwiperSlide>
    ))
  }, [nodes, isMb])

  const indicatorConfig = {
    totalCount: contentSlides.length,
    currentIndex: activeIndex,
    isMb,
    ...(isAnimation && {
      animationTime: `${autoplayDelay / 1000}s`,
    }),
  }

  // 滑动
  const onSlideChange = (swiper) => {
    const { activeIndex } = swiper
    setActiveIndex(activeIndex)
    onChange && onChange(activeIndex)
  }

  // 窗口大小变化进行重新渲染
  useEffect(() => {
    const handleResize = debounce(() => {
      setSwiperKey((prevKey) => prevKey + 1)
    }, 50)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useImperativeHandle(ref, () => ({
    slideTo: (index: number, speed?: number, runCallbacks?: boolean) => {
      swiperRef.current?.slideTo(index, speed, runCallbacks)
    },
  }))

  return (
    <div className="w-[100%] relative">
      <Swiper
        key={swiperKey}
        onSwiper={(swiperInstance) => (swiperRef.current = swiperInstance)}
        className={className}
        onSlideChange={onSlideChange}
        initialSlide={activeIndex}
        spaceBetween={30}
        autoplay={isAutoplay && !isMb ? { delay: autoplayDelay, disableOnInteraction: false } : false} // 添加autoplay属性
        // slidesPerView={swiperType === 'default' ? undefined : 'auto'} // 让多个 slide 在一个视图内显示
        slidesPerView={'auto'} // 让多个 slide 在一个视图内显示
      >
        {contentSlides}
      </Swiper>
      {indicatorType === 'default' && (
        <div
          style={
            isMb
              ? {
                  marginTop: mbGapMap[indicatorGapType],
                }
              : {
                  marginTop: '40px',
                  marginBottom: '30px',
                }
          }
        >
          <SwiperIndicator {...indicatorConfig} />
        </div>
      )}
      {indicatorType === 'bottom' && (
        <div
          className={
            isMb ? `mt-[${mbGapMap[indicatorGapType]}]` : 'absolute z-[9] bottom-[25px] left-[50%] -translate-x-[50%]'
          }
        >
          {/* 如果需要改这些值，可以新建type 或者传入一个对象，将这些值全部设置 */}
          <SwiperIndicator
            width={indicatorTypeWS.bottom[0]}
            spacing={indicatorTypeWS.bottom[1]}
            backgroundColor={'#ffffff'}
            onIndicatorClick={(index) => swiperRef.current?.slideTo(index)}
            {...indicatorConfig}
          />
        </div>
      )}
    </div>
  )
}

const SwiperCom = forwardRef<SwiperComInstance, SwiperComProps>(SwiperComInner)

export default SwiperCom
