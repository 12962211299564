import React from 'react'
import classnames from 'classnames'
import SectionContent from 'components/SectionContent'
import Button, { ButtonGroup, ButtonProps, ButtonType } from 'components/Base/Button'
import * as styles from './index.module.less'
import { Languages } from 'i18n/config'
import { ScanCodeConsultButton, VideoBtn } from '../../index'

interface QrCodeButtonProps {
  // 是否为二维码按钮
  useQrCode?: boolean
  // 二维码
  qrcode?: string
  buttonText?: string
  imgBottomText?: string
  btnType?: string
  ghost?: boolean
}

export interface buttonsProps extends ButtonProps, QrCodeButtonProps {
  text?: string
}

/**
 * 页面 banner 组件
 * @param {object} param0
 * @param {string} param0.title - banner的标题
 * @param {string} param0.intro - banner的简介
 * @param {string} param0.desc - banner的详细描述
 * @param {string} param0.img - banner的右侧配图
 * @param {string} param0.flex - banner内容布局格式； 默认 'between'; 可选 'center' 居中
 *
 * @param {Array} param0.buttons - banner的按钮列表
 * @param {String} param0.buttons[0].text -  按钮内容
 * @param {String} param0.buttons[0].url -  跳转链接
 * @param {Function} param0.buttons[0].callback -  按钮点击事件函数
 *
 * @param {string} [param0.className] - 支持传入 className 属性，
 */
interface AttrsProps {
  lang?: string
  /**
   * 跳转url链接
   */
  title?: string
  /**
   * 文案
   */
  intro?: string
  /**
   * taget打开方式是否_blank
   */
  desc?: string
  /**
   * 指定a标签样式-参考button.scss文件
   */
  img?: string
  /**
   * 指定a标签样式-参考button.scss文件
   */
  flex?: string
  /**
   * 指定a标签样式-参考button.scss文件
   */
  buttons?: buttonsProps[]
  className?: string
  bannerTitleClassName?: string
  bannerDescClassName?: string
  logoImg?: string
  logoName?: string
  bannerClassname?: string
  style?: any
  h1tag?: boolean // 是否为h1标签
  curVideo?: any // 播放视频的ref
  duration?: string // 视频时长
  videoBtnWraClassName?: string
  subTitle?: string // 副标题
  bannerSubTitleClassName?: string //副标题样式
  bannerBtnsClass?: string //按钮样式
  /**英文标题 */
  enTitle?: string
  enTitleClassName?: string
}

const BannerNew: React.FC<AttrsProps> = ({
  className = '',
  lang = Languages.CN,
  title = '',
  intro,
  desc,
  img,
  flex,
  logoImg,
  logoName,
  bannerClassname,
  bannerTitleClassName,
  bannerDescClassName,
  enTitleClassName,
  buttons = [],
  h1tag = false,
  style = {},
  curVideo,
  duration,
  videoBtnWraClassName,
  subTitle,
  bannerSubTitleClassName,
  bannerBtnsClass,
  enTitle = '',
}) => {
  return (
    <SectionContent
      className={classnames({
        [styles.banner]: true,
        [className]: true,
        [styles.banner_bg]: !img && lang === Languages.CN,
        [styles.banner_en_bg]: !img && lang === Languages.EN,
      })}
      style={style}
    >
      <div
        className={classnames(styles.banner_container, 'flex', 'flex-x-between', flex ? styles[`flex-${flex}`] : '')}
      >
        <div className={classnames(styles.banner_content, bannerClassname)}>
          {(logoImg || logoName) && (
            <p className={classnames(styles.logo)}>
              {logoImg && <img src={logoImg} alt="" />}
              {logoName && <span>{logoName}</span>}
            </p>
          )}
          {h1tag ? (
            <div className={styles.banner_titleWrapper}>
              <h1>
                <span
                  className={classnames(styles.banner_title, bannerTitleClassName)}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                {enTitle && <span className={classnames(styles.enTitle, enTitleClassName)}>{enTitle}</span>}
              </h1>
              {curVideo && (
                <div className={classnames(styles.videoBtnWra, videoBtnWraClassName)}>
                  <VideoBtn duration={duration || ''} curVideo={curVideo} />
                </div>
              )}
            </div>
          ) : (
            <h2 className={classnames(styles.banner_title)} dangerouslySetInnerHTML={{ __html: title }} />
          )}

          {intro ? (
            <span
              className={classnames(styles.banner_intro, 'sd-font-14')}
              dangerouslySetInnerHTML={{ __html: intro }}
            />
          ) : (
            ''
          )}
          {subTitle && <h2 className={classnames(styles.banner_title, bannerSubTitleClassName)}>{subTitle}</h2>}
          <p
            className={classnames(styles.banner_desc, bannerDescClassName)}
            dangerouslySetInnerHTML={{ __html: desc || '' }}
          />
          <ButtonGroup className={classnames(styles.banner_buttons, bannerBtnsClass)}>
            {buttons?.map((el: buttonsProps, i: number) =>
              el.text ? (
                <Button
                  key={i}
                  {...el}
                  ghost={el.ghost}
                  href={el.href}
                  className={el.className}
                  target={el.target || '_blank'}
                  btnType={el.btnType}
                  onClick={(e) => {
                    if (el.onClick) {
                      e.preventDefault()
                      el.onClick(e)
                    }
                  }}
                >
                  {el.text}
                </Button>
              ) : el.useQrCode ? (
                <ScanCodeConsultButton key={i} {...el} />
              ) : null,
            )}
          </ButtonGroup>
        </div>
        {/* <div className={classnames(styles.banner_media)}>
          {img && <img className={classnames(styles.banner)} src={img} alt="" />}
        </div> */}
      </div>
    </SectionContent>
  )
}

export default BannerNew
