// extracted by mini-css-extract-plugin
export var content = "index-module--content--Smqw7";
export var contentTitle = "index-module--contentTitle--rw2gC";
export var contetnWrapper = "index-module--contetnWrapper--8nxnu";
export var icon = "index-module--icon--pQDwI";
export var img = "index-module--img--Qlz7G";
export var mRleft = "index-module--mRleft--CxiWR";
export var mRright = "index-module--mRright--TEugP";
export var mbItemBoxShadow = "index-module--mbItemBoxShadow--pOCLL";
export var mbItemWrapper = "index-module--mbItemWrapper--8ZWhR";
export var moreResourcesWrapper = "index-module--moreResourcesWrapper--vE6cr";
export var redirectText = "index-module--redirectText--kpf-R";
export var redirectTipWrapper = "index-module--redirectTipWrapper--0DZy2";
export var rightItem = "index-module--rightItem--57-UV";
export var tag = "index-module--tag--9dQSp";
export var text = "index-module--text--CY1-g";
export var title = "index-module--title--Ofmhn";