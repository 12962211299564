/** 视频列表 */
import React, { useState, useCallback, useRef, useEffect } from 'react'
import classNames from 'classnames'
import MoreButton from '../MoreButton'
import { VideoPlayer } from '../../index'
import { debounce } from 'lodash'
import * as styles from './index.module.less'

interface ListItemProps {
  img: string | any
  title: string
  href: string
  active?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  setVideoUrl: (url: string) => void
}

const ListItem: React.FC<ListItemProps> = ({
  img,
  href,
  title,
  active = false,
  onMouseEnter,
  onMouseLeave,
  setVideoUrl,
}) => {
  return (
    <div
      className={classNames(styles.listItemRoot, {
        [styles.active]: active,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => setVideoUrl(href)}
    >
      <div className={styles.imageWrapper}>
        <img src={img?.publicURL ?? img} alt={title} />
      </div>
      <div className={styles.descriptionWrapper}>
        <p>{title}</p>
      </div>
    </div>
  )
}

interface VideoListProps {
  data: ListItemProps[]
}

const VideoList: React.FC<VideoListProps> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [videoUrl, setVideoUrl] = useState<string | null>(null)
  const curVideo = useRef<{ playVideo: () => void }>()

  useEffect(() => {
    videoUrl &&
      setTimeout(() => {
        curVideo?.current?.playVideo()
      }, 100)
  }, [videoUrl])

  const debouncedSetIndex = useCallback(
    debounce((idx) => setActiveIndex(idx), 50),
    [],
  )

  const displayData = isOpen ? data : data.slice(0, 7)

  return (
    <>
      <div className={styles.listWrapper}>
        {displayData.map((item, idx) => (
          <ListItem
            key={idx}
            {...item}
            active={idx === activeIndex}
            onMouseEnter={() => debouncedSetIndex(idx)}
            onMouseLeave={() => debouncedSetIndex(0)}
            setVideoUrl={setVideoUrl}
          />
        ))}
        <MoreButton
          isOpen={isOpen}
          onClick={() => setIsOpen(!isOpen)}
          openText={'点击展开全部'}
          closeText={'收起全部'}
        ></MoreButton>
      </div>
      {videoUrl && (
        <VideoPlayer
          videoOpt={{
            sources: videoUrl,
          }}
          fuRef={curVideo}
          showLoadTip
          onClose={() => setVideoUrl(null)}
        />
      )}
    </>
  )
}

export default VideoList
