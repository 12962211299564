import React from 'react'
import * as styles from './index.module.less'

interface SummaryTitleProps {
  /** 标题 */
  title: string
  /** 描述 */
  desc?: string
}

const SummaryTitle: React.FC<SummaryTitleProps> = ({ title, desc }) => {
  return (
    <div className={styles.titleWrapper}>
      <h2>{title}</h2>
      {desc && <div className={styles.desc}>{desc}</div>}
    </div>
  )
}

export default SummaryTitle
