import React from 'react'
import * as styles from './index.module.less'
interface MoreButtonProps {
  isOpen?: boolean
  onClick?: (b: boolean) => void
  openText?: string
  closeText?: string
}

const MoreButton: React.FC<MoreButtonProps> = ({ isOpen = false, onClick, openText = '', closeText = '' }) => {
  return (
    <div className={styles.moreButton} onClick={() => onClick?.(!isOpen)}>
      {isOpen ? closeText : openText}
      <img
        style={isOpen ? { transform: 'rotate(180deg)' } : {}}
        src={require('assets/images/researchInstitute/arrow_down.svg').default}
        alt=""
      />
    </div>
  )
}

export default MoreButton
