import React from 'react'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import classnames from 'classnames'

import * as styles from './index.module.less'
import Button from 'components/Base/Button'

interface dataProp {
  buttonText?: string
  desc?: string
  link?: string
  title?: string
  icon?: any
}
interface IntroduceProps {
  data?: dataProp[]
  buttonText?: string
  wxIcon?: any
  moreIcon?: any
  buttonNext?: string
  link?: string
  /**微信咨询为白色的按钮 */
  whiteWxBtn?: boolean
  /**是否为静态展示 */
  showStatic?: boolean
}

export const Digital: React.FC<IntroduceProps> = ({
  data,
  moreIcon,
  buttonText,
  wxIcon,
  buttonNext,
  link,
  whiteWxBtn = false,
  showStatic = false,
}) => {
  return (
    <div
      className={classnames('bg-[#F9FAFC] w-full mx-auto my-0 md:py-0 py-[3rem] lg:py-auto lg:mt-[9px] lg:w-[1200px]')}
    >
      <ul className="flex justify-between flex-wrap w-[33.5rem] mx-auto my-0 lg:w-full">
        {data?.map((item) => (
          <li
            key={item?.title}
            className={classnames({
              'bg-[#fff] relative flex items-center lg:block w-[16.4rem] h-[8rem] lg:w-[284px] lg:h-[175px] px-[1.2rem] lg:px-[18px] mb-[7px] lg:mb-[20px] rounded-[4px]':
                true,
              [styles.digitalItem]: true,
              'hover:shadow-[0_2px_10px_0_rgb(193,208,211,42%)]': !showStatic,
              'cursor-pointer': !showStatic,
            })}
            onClick={() => !showStatic && window.open(item.link)}
          >
            <p className="inline-block w-[3.6rem] lg:w-[42px] relative lg:right-[17px] lg:top-[25px] lg:absolute">
              <img src={item?.icon?.publicURL ?? item?.icon} />
            </p>
            <p
              className={classnames({
                'inline-block text-[#475669] text-[14px] font-medium lg:text-[#181818] lg:text-[18px] lg:mt-[32px] ml-[17px] lg:ml-auto lg:w-auto w-[8.4rem]':
                  true,
                [styles.title]: !showStatic,
              })}
            >
              {item.title}
            </p>
            <p className="hidden lg:block mt-[15px] text-[#475669] lg:text-[14px]">{item.desc}</p>
            {!showStatic && (
              <a href={item.link} className={classnames('hidden lg:flex', styles.more)} target="_blank">
                <span className="mr-[7px]">{item.buttonText}</span>
                <img src={moreIcon?.publicURL ?? moreIcon} />
                <img src={moreIcon?.publicURL ?? moreIcon} />
              </a>
            )}
          </li>
        ))}
      </ul>
      {whiteWxBtn ? (
        <div className="text-center mt-[3rem] lg:mt-[22px]">
          <Button href={link} target="_blank" btnType="primary">
            {buttonNext}
          </Button>
          <ScanCodeConsultButton
            className="ml-[1rem] bg-[#fff] hover:!bg-[#E6FAF5] lg:ml-[20px]"
            buttonText={buttonText}
            imgBottomText="微信扫码 即刻咨询"
            qrcode={wxIcon?.publicURL ?? wxIcon}
            ghost
            buttonType="primary"
          />
        </div>
      ) : (
        <div className="text-center mt-[3rem] lg:mt-[22px]">
          <ScanCodeConsultButton
            buttonText={buttonText}
            imgBottomText="微信扫码 即刻咨询"
            qrcode={wxIcon?.publicURL ?? wxIcon}
          />
          <Button href={link} target="_blank" btnType="primary" ghost className="ml-[1rem] lg:ml-[20px]">
            {buttonNext}
          </Button>
        </div>
      )}
    </div>
  )
}

export default Digital
