import React from 'react'
import classnames from 'classnames'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import { Button, ScanCodeConsultButton } from 'components/index'
import * as styles from './index.module.less'

interface ImgDescProps {
  /**描述数组 */
  descArr: any
  /**图片链接 */
  imgUrl: any
  /**左边按钮文案 */
  leftBtnText?: string
  /**左边按钮跳转链接 */
  leftBtnLink?: string
  /**右边按钮文案 */
  rightBtnText?: string
  /**微信二维码图片 */
  wxIcon: any
}

const ImgDesc: React.FC<ImgDescProps> = (props) => {
  const { descArr, imgUrl, leftBtnText = '体验 Demo', leftBtnLink, rightBtnText = '了解更多', wxIcon } = props
  const isMb = useIsWindowWidthSmaller()
  return isMb ? (
    <div className={styles.imgDescMbWrapper}>
      <div className={styles.img}>
        <img src={imgUrl?.publicURL ?? imgUrl} />
      </div>
      <div className={styles.content}>
        <div>
          {descArr.map((item) => {
            return (
              <div className={styles.descItem} key={`ImgDesc+${item.title}`}>
                <div className={styles.descItemTitle}>{item.title}</div>
                <div className={styles.descItemDesc}>{item.desc}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.imgDescWrapper}>
      <div className={styles.content}>
        <div>
          {descArr.map((item) => {
            return (
              <div className={styles.descItem} key={`ImgDesc+${item.title}`}>
                <div className={styles.descItemTitle}>{item.title}</div>
                <div className={styles.descItemDesc}>{item.desc}</div>
              </div>
            )
          })}
        </div>
        <div className={styles.img}>
          <img src={imgUrl?.publicURL ?? imgUrl} />
        </div>
      </div>
      <div className={styles.btns}>
        <Button btnType="primary" className={styles.scenarioCard_primaryBtn} href={leftBtnLink} target="_blank">
          {leftBtnText}
        </Button>
        <ScanCodeConsultButton
          className="ml-[36px]"
          buttonText={rightBtnText}
          imgBottomText="微信扫码 即刻咨询"
          qrcode={wxIcon?.publicURL ?? wxIcon}
          ghost
          buttonType="primary"
        />
      </div>
    </div>
  )
}

export default ImgDesc
