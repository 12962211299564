import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import * as styles from './index.module.less'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'

interface ImagePreviewProps {
  imageUrl: string
  alt?: string
  pcImgClassName?: string
  language?: string
}

enum Language {
  'zh_CN' = '点击图片查看大图',
  'en' = 'Click to enlarge image',
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ imageUrl, alt = '', pcImgClassName, language = 'zh_CN' }) => {
  const [showOriginal, setShowOriginal] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [shift, setShift] = useState({ x: 0, y: 0 })
  const isMb = useIsWindowWidthSmaller()

  const handleImageClick = () => {
    setShowOriginal(!showOriginal)
  }

  const handleInteractionStart = (e: React.TouchEvent) => {
    setDragging(true)
    setPosition({ x: e.touches[0].clientX, y: e.touches[0].clientY })
  }

  const handleInteractionMove = (e: React.TouchEvent) => {
    e.stopPropagation()
    const clientX = e.touches[0].clientX
    const clientY = e.touches[0].clientY

    if (dragging) {
      setShift((prevShift) => ({
        x: prevShift.x + clientX - position.x,
        y: prevShift.y + clientY - position.y,
      }))
      setPosition({ x: clientX, y: clientY })
    }
  }

  const handleInteractionEnd = () => {
    setDragging(false)
  }

  useEffect(() => {
    if (showOriginal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [showOriginal])

  return (
    <>
      {isMb ? (
        <div
          className={classNames(styles.imagePreview, { [styles.original]: showOriginal })}
          onClick={handleImageClick}
        >
          <img src={imageUrl} alt={alt} />
          <div className={styles.tip}>{Language[language]}</div>
        </div>
      ) : (
        <img src={imageUrl} alt={alt} className={pcImgClassName} />
      )}
      {showOriginal &&
        ReactDOM.createPortal(
          <div
            className={styles.overlay}
            onClick={(e) => {
              e.stopPropagation()
              handleImageClick()
            }}
            onTouchStart={handleInteractionStart}
            onTouchMove={handleInteractionMove}
            onTouchEnd={handleInteractionEnd}
            style={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={imageUrl}
              alt={alt}
              style={{
                position: 'absolute',
                left: `calc(50% + ${shift.x}px)`,
                top: `calc(50% + ${shift.y}px)`,
                transform: 'translate(-50%, -50%)',
                width: 'auto',
                height: '80%',
              }}
            />
          </div>,
          document.body,
        )}
    </>
  )
}

export default ImagePreview
