import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import React from 'react'
import * as styles from './index.module.less'

interface SimpleBannerProps {
  title: string
  desc: string
  img: string
  /** 移动端背景图 */
  mbImg: string
  /** 标题样式 */
  titleStyle?: React.CSSProperties
  /** 描述样式 */
  descStyle?: React.CSSProperties
  /** 文字距离顶部的距离，默认200px */
  topDistance?: number

  topDistanceMb?: string | number
}

const SimpleBanner: React.FC<SimpleBannerProps> = ({
  title,
  desc,
  img,
  mbImg,
  titleStyle = {},
  descStyle = {},
  topDistance = 200,
  topDistanceMb = '9.8rem',
}) => {
  const isMb = useIsWindowWidthSmaller()

  return (
    <section
      className={styles.bannerWrapper}
      style={{ backgroundImage: `url(${isMb ? mbImg : img}),linear-gradient(to right, #FFFFFF 50%, #F5F8F6 50%)` }}
    >
      <article className={styles.articleWrapper} style={{ paddingTop: isMb ? topDistanceMb : topDistance }}>
        <h1 className={styles.title} style={titleStyle}>
          {title}
        </h1>
        <p className={styles.description} style={descStyle}>
          {desc}
        </p>
      </article>
    </section>
  )
}

export default SimpleBanner
