import React from 'react'
import * as styles from './index.module.less'

interface SwiperIndicatorProps {
  /** 总数 */
  totalCount: number
  /** 下标 */
  currentIndex: number
  /** 移动端 */
  isMb?: boolean
  /** 单个宽度 */
  width?: number
  /** 间隔 */
  spacing?: number
  /** 背景颜色 */
  backgroundColor?: string
  /** 点击事件 */
  onIndicatorClick?: (index: number) => void
  /** 是否动画效果 */
  animationTime?: string
}

/** swiper 底部的状态条 */
const SwiperIndicator: React.FC<SwiperIndicatorProps> = ({
  totalCount,
  currentIndex,
  width = 14,
  spacing = 7,
  backgroundColor = '#eaeaea',
  isMb = true,
  onIndicatorClick,
  animationTime = '0s',
}) => {
  const renderIndicators = () => {
    const indicators: any[] = []

    for (let i = 0; i < totalCount; i++) {
      indicators.push(
        <div
          key={i}
          className={`${isMb ? styles.indicatorMb : styles.indicator} ${i === currentIndex ? styles.active : ''} ${
            onIndicatorClick ? 'cursor-pointer' : ''
          }`}
          onClick={() => onIndicatorClick && onIndicatorClick(i)}
          style={
            isMb
              ? {}
              : {
                  width,
                  marginRight: i < totalCount - 1 ? spacing : 0,
                  backgroundColor,
                }
          }
        >
          <div
            className={styles.colorBlock}
            style={{
              animationDuration: animationTime,
            }}
          ></div>
        </div>,
      )
    }

    return indicators
  }

  return <div className={styles.indicatorWrapper}>{renderIndicators()}</div>
}

export default SwiperIndicator
